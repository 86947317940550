import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
// eslint-disable-next-line @nx/enforce-module-boundaries
import DriveScorePartnerWithUs from '@clearscore/website.drivescore-partner-with-us';

import socialMediaImageGb from '../assets/global/drivescore/avatar_drivescore.png';
import Layout from '../components/layout';

const PRIVACY_COOKIES_URL = 'https://clearscore.com/drive-score-privacy-policy';

const DriveScoreTemplate = ({
    data,
    location,
}: PageProps<Queries.DriveScorePartnerWithUsQuery>): React.ReactElement => {
    const images = {
        appPreviewHeroLensFlare: getImage(data.appPreviewHeroLensFlare as ImageDataLike),
    };

    return (
        <Layout
            meta={{
                title: 'DriveScore | Partner with us',
                description: 'Partner with us',
                image: socialMediaImageGb,
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <DriveScorePartnerWithUs
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={{
                        ...commonModules.cookie_policy,
                        // Overwritten for this page only
                        moreInfoLinkUrl: PRIVACY_COOKIES_URL,
                    }}
                    images={images}
                    privacyUrl={PRIVACY_COOKIES_URL}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query DriveScorePartnerWithUs {
        appPreviewHeroLensFlare: file(absolutePath: { regex: "/drivescore/heroLensFlare.png/" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 99
                    placeholder: BLURRED
                    breakpoints: [375, 768, 1024, 1200, 1440]
                    layout: FULL_WIDTH
                )
            }
        }
    }
`;

export default DriveScoreTemplate;
