import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import Loader from '@clearscore/ui.rainbow.drivescore.loader';
import type { IClickableItemProps } from '@clearscore/ui.rainbow.drivescore.clickable-item';
import ClickableItem from '@clearscore/ui.rainbow.drivescore.clickable-item';

import styles from './button.module.css';
import { Type, HtmlType, Size } from './constanst';

interface IButton {
    children: ReactElement | string;
    loaderAriaLabel?: string;
    dataId?: string;
    Icon?: typeof React.Component;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    isLoading?: boolean;
    isUppercase?: boolean;
    size?: (typeof Size)[keyof typeof Size];
    type?: (typeof Type)[keyof typeof Type];
}

export interface ButtonExport {
    (props: IButton & IClickableItemProps): ReactElement;
    Size: typeof Size;
    Type: typeof Type;
    HtmlType: typeof HtmlType;
}

const Button: ButtonExport = ({
    children,
    loaderAriaLabel = 'loading',
    dataId = '',
    Icon,
    isDisabled = false,
    isFullWidth = false,
    isUppercase = false,
    isLoading = false,
    size = Size.MEDIUM,
    type = Type.PRIMARY,
    ...props
}) => {
    const typeClass = styles[`type_${type}`];
    const sizeClass = styles[`size_${size}`];
    const loaderSize = size === Size.SMALL ? Loader.Size.TINY : Loader.Size.MEDIUM;
    return (
        <ClickableItem dataId={dataId} isFullWidth={isFullWidth} isDisabled={isDisabled} {...props}>
            <div
                data-id={`${dataId}-ui`}
                data-qa="button-ui"
                className={cx(styles.button, typeClass, sizeClass, {
                    [styles.withIcon]: Icon,
                    [styles.disabled]: isDisabled,
                    [styles.fullWidth]: isFullWidth,
                    [styles.upperCase]: isUppercase,
                })}
            >
                {Icon && !isLoading ? <Icon className={cx(styles.icon, typeClass, sizeClass)} role="img" /> : null}
                {isLoading ? <Loader ariaLabel={loaderAriaLabel} size={loaderSize} /> : children}
            </div>
        </ClickableItem>
    );
};

Button.Size = Size;
Button.Type = Type;
Button.HtmlType = HtmlType;

export default Button;
