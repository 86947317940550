export const Type = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    TERTIARY: 'TERTIARY',
} as const;

export const HtmlType = {
    SUBMIT: 'submit',
    BUTTON: 'button',
    RESET: 'reset',
} as const;

export const Size = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
} as const;
