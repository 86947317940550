/* eslint-disable prefer-rest-params */
import isEmpty from '@clearscore-group/lib.validation.is-empty';

export const DEFAULT_MESSAGE = 'Required field';

/*
 * isRequired Validation
 * isRequired checks to see if there is any content
 * isRequired returns the DEFAULT_MESSAGE if no content
 */
function isRequired(value) {
    if (value === 'false' || typeof value === 'object') {
        return false;
    }
    if (typeof value === 'boolean') {
        return value;
    }
    return value === '-1' || !isEmpty(value);
}

export function isRequiredValidation() {
    const errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_MESSAGE;
    return function error(value) {
        return !isRequired(value) ? errorMessage : undefined;
    };
}

export function isRequiredEmailConfirmValidation(errorMessage) {
    return function error(value, allValues) {
        return allValues.email_confirm !== allValues.email ? errorMessage : undefined;
    };
}

export default isRequired;
