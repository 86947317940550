import type { ReactElement } from 'react';
import React from 'react';
import cx from 'classnames';
import toTitleCase from '@clearscore-group/lib.normalize.title-case';

import styles from './loader.module.css';

const Size = {
    TINY: 'TINY',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
} as const;

interface ILoaderProps {
    ariaLabel?: string;
    isFullPage?: boolean;
    size?: (typeof Size)[keyof typeof Size];
}

export interface LoaderExport {
    (props: ILoaderProps): ReactElement;
    Size: typeof Size;
}

const Loader: LoaderExport = ({ ariaLabel = 'loading', size = Size.MEDIUM, isFullPage = false }) => {
    const loader = (
        <div
            aria-live="polite"
            aria-label={ariaLabel}
            data-qa="loader"
            className={cx(styles.loader, styles[`isSize${toTitleCase(size)}`])}
        >
            <svg viewBox="0 0 100 100">
                <defs>
                    <linearGradient
                        className={styles.gradient}
                        id="ds-loader-gradient"
                        spreadMethod="pad"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0%" className={styles.stopColourStart} />
                        <stop offset="100%" className={styles.stopColourEnd} />
                    </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="43" className={styles.circleInprint} />
                <circle cx="50" cy="50" r="43" className={styles.circle} stroke="url(#ds-loader-gradient)" />
            </svg>
        </div>
    );

    if (isFullPage) {
        return (
            <div className={styles.container} data-qa="loader-container">
                {loader}
            </div>
        );
    }

    return loader;
};

Loader.Size = Size;

export default Loader;
