const VALID_METHODS = ['DELETE', 'PATCH', 'PUT', 'POST'];

const INCORRECT_METHOD_ERROR = (method) =>
    `ERROR [use-cs-mutate-query]: Incorrect HTTP method'${method}'.
    Please use one of the following ${VALID_METHODS.toString()}`;

const fetcher = async (client, config) => client.request(config);

const useCSMutateQuery = ({ client, endpoint, data, headers, method, params }) => {
    if (!VALID_METHODS.includes(method.toUpperCase())) throw Error(INCORRECT_METHOD_ERROR(method));

    return fetcher(client, {
        client,
        method,
        url: endpoint,
        params,
        data,
        headers,
    });
};

export default useCSMutateQuery;
