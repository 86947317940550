import React from 'react';
import PropTypes from 'prop-types';

import PartnerForm from '../partner-form';
import styles from './hero.module.css';

const Hero = ({ header, subheader }) => (
    <section className={styles.heroSection} data-id="hero-section">
        <div className={styles.headerWrapper}>
            <h1 className={styles.header}>{header}</h1>
            <h2 className={styles.subheader}>{subheader}</h2>
        </div>
        <PartnerForm />
    </section>
);

Hero.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
};

export default Hero;
