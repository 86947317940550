import React from 'react';
import PropTypes from 'prop-types';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Template from '@clearscore/shared.website-template';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Footer, NavigationBar } from '@clearscore/shared.website-drivescore-sections';

import lang from './lib/lang';
import Hero from './components/hero';
import styles from './drivescore-partner-with-us.module.css';

const DrivescorePartnerWithUs = ({ appStoreConfig, cookiePolicyConfig, images, privacyUrl }) => {
    const { hero } = useLanguage(lang);

    return (
        <Template
            shouldHideNav
            shouldHideFooter
            appStoreConfig={appStoreConfig}
            cookiePolicyConfig={cookiePolicyConfig}
            pullUpContent
        >
            <div className={styles.drivescoreLandingBackground}>
                <div className={styles.heroGradient} />
                <div aria-hidden className={styles.heroGlitter}>
                    <GatsbyImage image={images.appPreviewHeroLensFlare} alt="" style={{ position: 'unset' }} />
                </div>
                <main className={styles.contentWrapper}>
                    <NavigationBar />
                    <Hero {...hero} />
                    <Footer privacyUrl={privacyUrl} />
                </main>
            </div>
        </Template>
    );
};

DrivescorePartnerWithUs.propTypes = {
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,

    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    images: PropTypes.shape({
        appPreviewHeroLensFlare: PropTypes.object.isRequired,
    }).isRequired,
    privacyUrl: PropTypes.string.isRequired,
};

export default DrivescorePartnerWithUs;
