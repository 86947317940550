import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        formHeader: 'Get in touch with us',
        name: {
            errorMessage: 'Enter your name',
            label: 'Full name *',
            placeholder: 'Full name',
        },
        email: {
            errorMessage: 'Enter your email',
            label: 'Email *',
            placeholder: 'Email',
        },
        company: {
            errorMessage: 'Enter the company name',
            label: 'Company *',
            placeholder: 'Company',
        },
        message: {
            label: 'Message',
            placeholder: 'Enter your message (optional)',
        },
        submitButtonCta: 'SUBMIT',
        submitSuccess:
            'Thanks for reaching out! Someone from our team will follow up with you soon on <0><%= userEmail %></0>.',
        submitError: 'We’ve run into a connection problem.',
    },
};
