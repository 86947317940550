import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        hero: {
            header: 'Want to partner with DriveScore?',
            subheader:
                "We're always keen to hear from like-minded businesses interested in working with DriveScore. If that is you, please enter your details here, and we'll get in touch.",
        },
    },
};
